<template>
  <div class="dashboard">
    <b-container class="shlajsna">
      <b-row>
        <b-col cols="12">
          <h1 class="text-center">{{ $t('language.add-language') }}</h1>
        </b-col>
        <b-col cols="12">
          <NavBar></NavBar>
        </b-col>
        <b-col cols="12" class="pl-4">
          <LanguageForm />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import NavBar from "@/components/common/NavBar.vue"
import LanguageForm from '@/components/admin/langauge/LanguageForm.vue'
export default {
  name : "AddLanguage",
  components : { NavBar, LanguageForm },
  data: function() {
    return {
      showOverlay: false
    }
  },
  created: function() {
  }
}
</script>

<style></style>
